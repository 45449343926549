'use strict';

const appendToUrl = require('../util/appendToUrl');

const spinnerModal = $('#modalPreferredStore').spinner();

/**
 *
 */
function getFavoriteStore() {
    $('#jquery-nofavoritestore').hide();
    const favStoreSelector = '#jquery-modal-storedelivery-favorite-container';
    let url = $('#modalPreferredStore').data('get-fav-store-id');
    if (!url) return;
    spinnerModal.start();
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (res) {
            spinnerModal.stop();
            if (res.store && res.store.ID) {
                spinnerModal.stop();
                $(`${favStoreSelector}`).html(res.storeHtml);
                $(`${favStoreSelector}`)
                    .find('.jquery-modal-storedelivery-makefavorite')
                    .remove();
            } else {
                $(`${favStoreSelector}`).empty();
                $('#jquery-nofavoritestore').show();
            }
        }
    });
}

module.exports = {
    init: function () {
        $('#jquery-nofavoritestore').hide();
        getFavoriteStore();
    },
    setFavoriteStore: () => {
        $('body').on('change', '.jquery-checkbox-set-favorite-store', function () {
            if (this.checked === false) {
                // Only if checkbox is checked
                return;
            }
            // Set all checkboxes to 'unchecked'
            $('.jquery-checkbox-set-favorite-store').prop('checked', false);
            $('.jquery-checkbox-set-favorite-store').prop(
                'disabled',
                false
            );
            // Set now active checkbox 'checked' and 'disabled'
            $(this).prop('checked', true);
            $(this).prop('disabled', true);

            // POST AJAX request to save new favorite store
            let url = $(this).data('set-favorite-store-url');
            let urlParams = {
                storeID: $(this).data('store-id')
            };
            url = appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: 'post',
                success: function () {
                    getFavoriteStore();
                }
            });
        }
        );
    }
};
