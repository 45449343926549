'use strict';

var addressHelpers = require('../base/checkout/address');
var shippingHelpers = require('./shipping');
var billingHelpers = require('./billing');
var summaryHelpers = require('../base/checkout/summary');
var formHelpers = require('../base/checkout/formErrors');
var scrollAnimate = require('../base/components/scrollAnimate');
var bpost = require('./bpost');

var removeAliasUrl;
var removeAliasId;

/**
 *
 */
function submitPaymentForm() {
    formHelpers.clearPreviousErrors('.payment-form');

    var contactInfoForm = $(
        '#dwfrm_billing .contact-info-block'
    ).serialize();

    $('body').trigger('checkout:serializeBilling', {
        form: $('#dwfrm_billing .contact-info-block'),
        data: contactInfoForm,
        callback: function (data) {
            if (data) {
                contactInfoForm = data;
            }
        }
    });

    var paymentInfoSelector = '#dwfrm_billing';
    var paymentInfoForm = $(paymentInfoSelector).serialize();

    $('body').trigger('checkout:serializeBilling', {
        form: $(paymentInfoSelector),
        data: paymentInfoForm,
        callback: function (data) {
            if (data) {
                paymentInfoForm = data;
            }
        }
    });
    var billingAddressForm = $('#dwfrm_billing .billing-address-block').serialize();
    var paymentForm =
                        billingAddressForm + '&' + contactInfoForm +
                        '&' +
                        paymentInfoForm;

    if (
        $('.data-checkout-stage').data('customer-type') ===
                        'registered'
    ) {
        // if payment method is credit card
        if (
            $('.payment-information').data(
                'payment-method-id'
            ) === 'CREDIT_CARD'
        ) {
            if (
                !$('.payment-information').data(
                    'is-new-payment'
                )
            ) {
                var $savedPaymentInstrument = $(
                    '.saved-payment-instrument' +
                                        '.selected-payment'
                );
                paymentForm += '&storedPaymentUUID=' + $savedPaymentInstrument.data('uuid');
            }
        }
    }
    // disable the next:Place Order button here
    $('body').trigger(
        'checkout:disableButton',
        '.next-step-button button'
    );

    $.ajax({
        url: $('#dwfrm_billing').attr('action'),
        method: 'POST',
        data: paymentForm,
        success: function (data) {
            // enable the next:Place Order button here
            $('body').trigger(
                'checkout:enableButton',
                '.next-step-button button'
            );
            // look for field validation errors
            if (data.error) {
                if (data.fieldErrors.length) {
                    data.fieldErrors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors(
                                '.payment-form',
                                error
                            );
                        }
                    });
                }

                if (data.serverErrors.length) {
                    data.serverErrors.forEach(function (error) {
                        $('.error-message').show();
                        $('.error-message-text').text(error);
                        scrollAnimate($('.error-message'));
                    });
                }

                if (data.cartError) {
                    window.location.href = data.redirectUrl;
                }
            }
        },
        error: function (err) {
            // enable the next:Place Order button here
            $('body').trigger(
                'checkout:enableButton',
                '.next-step-button button'
            );
            if (err.responseJSON && err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
        }
    });
}

/**
 * handles realtime stock information product summary
 * @param {Object} stockInfo - realtime stock information
 */
function parseRealtimeStock(stockInfo) {
    if (stockInfo && Object.entries(stockInfo).length > 0) {
        for (const [id, stockItem] of Object.entries(stockInfo)) {
            if (stockItem) {
                if (!stockItem.stockIsAvailable) {
                    $('.availability-' + id + ' .available').addClass('d-none');
                    $('.availability-' + stockItem.id + ' .not-available').removeClass('d-none');
                }
            }
        }

        $('.js-stock-ok').addClass('d-none');
        $('.js-stock-error').removeClass('d-none');
    }
}

/**
 * Create the jQuery Checkout Plugin.
 *
 * This jQuery plugin will be registered on the dom element in checkout.isml with the
 * id of "checkout-main".
 *
 * The checkout plugin will handle the different state the user interface is in as the user
 * progresses through the varying forms such as shipping and payment.
 *
 * Billing info and payment info are used a bit synonymously in this code.
 *
 */
(function ($) {
    $.fn.checkout = function () { // eslint-disable-line
        var plugin = this;

        //
        // Collect form data from user input
        //
        var formData = {
            // Shipping Address
            shipping: {},

            // Billing Address
            billing: {},

            // Payment
            payment: {},

            // Gift Codes
            giftCode: {}
        };

        //
        // The different states/stages of checkout
        //
        var checkoutStages = ['shipping', 'placeOrder'];

        /**
         * Updates the URL to determine stage
         * @param {number} currentStage - The current stage the user is currently on in the checkout
         */
        function updateUrl(currentStage) {
            history.pushState(
                checkoutStages[currentStage],
                document.title,
                location.pathname +
                    '?stage=' +
                    checkoutStages[currentStage] +
                    '#' +
                    checkoutStages[currentStage]
            );
        }

        /**
         * Load bpost script when needed
         * @param {string} currentStage Current stage string
         */
        function checkToLoadBpost(currentStage) {
            if (checkoutStages[currentStage] === 'shipping' && !document.getElementById('bpostShippingAddress')) {
                bpost.loadSHM();
            }
        }

        //
        // Local member methods of the Checkout plugin
        //
        var members = {
            // initialize the currentStage variable for the first time
            currentStage: 0,

            /**
             * Set or update the checkout stage (AKA the shipping, billing, payment, etc... steps)
             * @returns {Object} a promise
             */
            updateStage: function () {
                var stage = checkoutStages[members.currentStage];
                var defer = $.Deferred(); // eslint-disable-line

                var billingForm = $('#dwfrm_billing .billing-address-block');
                var billingAddressForm = billingForm.serialize();
                var billingErrorMessage = billingForm.data('errormessage');

                var stockInfo;
                var confirmed;
                $('body').trigger('checkout:serializeBilling', {
                    form: $('#dwfrm_billing .billing-address-block'),
                    data: billingAddressForm,
                    callback: function (data) {
                        if (data) {
                            billingAddressForm = data;
                        }
                    }
                });

                if (stage === 'shipping') {
                    //
                    // check items out of stock
                    //
                    stockInfo = $('#realtimeStockInfo').data('stock');
                    confirmed = $('#realtimeStockInfo').attr('data-stock-confirmed');
                    if (stockInfo && Object.entries(stockInfo).length > 0 && confirmed !== 'true') {
                        $('#removeProductsModal').modal('show');
                        defer.reject();
                        return defer;
                    }

                    //
                    // Clear Previous Errors
                    //
                    formHelpers.clearPreviousErrors('.shipping-form');

                    //
                    // Submit the Shipping Address Form
                    //
                    var formSelector = '.single-shipping .shipping-form';
                    var form = $(formSelector);
                    var shippingErrorMessage = form.data('errormessage');

                    var shippingFormData = form.serialize();

                    $('body').trigger('checkout:serializeShipping', {
                        form: form,
                        data: shippingFormData,
                        callback: function (data) {
                            shippingFormData = data;
                        }
                    });

                    // disable the next:Payment button here
                    $('body').trigger(
                        'checkout:disableButton',
                        '.next-step-button button'
                    );
                    if (($('input#shippingMethod-storeDelivery').prop('checked') === true || $('input#shippingMethod-reservation').prop('checked') === true) && $('.shipping-address .address-summary[data-favstoreselected="false"]').length > 0) {
                        $('#modalPreferredStore').modal('show');

                        $('body').trigger(
                            'checkout:enableButton',
                            '.next-step-button button'
                        );

                        defer.reject();
                    } else {
                        $.ajax({
                            url: form.attr('action'),
                            type: 'post',
                            data: shippingFormData + '&' + billingAddressForm,
                            success: function (data) {
                                // enable the next:Payment button here
                                $('body').trigger(
                                    'checkout:enableButton',
                                    '.next-step-button button'
                                );
                                shippingHelpers.methods.shippingFormResponse(
                                    defer,
                                    data
                                );
                                if ($('input[name=dwfrm_billing_paymentMethod]:checked').length > 0) {
                                    submitPaymentForm();
                                }
                                if (data.error === true) {
                                    var errorMessage = '';
                                    if (data.form.valid === false) {
                                        errorMessage = shippingErrorMessage;
                                    }
                                    if (data.billingForm.valid === false) {
                                        errorMessage = billingErrorMessage;
                                    }
                                    $('#js-toastcontainer').replaceWith(
                                        `<div id="js-toastcontainer" class="toast-checkout alert alert-danger" role="alert">
                                            <p>${errorMessage}</p>
                                        </div>`
                                    );
                                } else if (data.orderProductSummaryTemplate) {
                                    $('.card.checkout-card .card-body .order-product-summary').empty().append(data.orderProductSummaryTemplate);
                                }
                            },
                            error: function (err) {
                                // enable the next:Payment button here
                                $('body').trigger(
                                    'checkout:enableButton',
                                    '.next-step-button button'
                                );
                                if (
                                    err.responseJSON &&
                                    err.responseJSON.redirectUrl
                                ) {
                                    window.location.href =
                                        err.responseJSON.redirectUrl;
                                }
                                // Server error submitting form
                                defer.reject(err.responseJSON);
                            }
                        });
                    }

                    return defer;
                } else if (stage === 'placeOrder') {
                    //
                    // check items out of stock
                    //
                    stockInfo = $('#realtimeStockInfo').data('stock');
                    confirmed = $('#realtimeStockInfo').attr('data-stock-confirmed');
                    if (stockInfo && Object.entries(stockInfo).length > 0 && confirmed !== 'true') {
                        $('#removeProductsModal').modal('show');
                        defer.reject();
                        return defer;
                    }

                    //
                    // Submit the Billing Address Form
                    //
                    // disable the placeOrder button here
                    $('body').trigger(
                        'checkout:disableButton',
                        'button.place-order'
                    );
                    $.spinner().start();
                    $.ajax({
                        url: $('.place-order').data('action'),
                        data: {
                            customerNote: $('textarea[name=customerNote]').val()
                        },
                        method: 'POST',
                        success: function (data) {
                            $.spinner().stop();
                            // enable the placeOrder button here
                            if (data.error) {
                                if (data.cartError) {
                                    window.location.href = data.redirectUrl;
                                    defer.reject();
                                } else {
                                    // go to appropriate stage and display error message
                                    defer.reject(data);
                                }
                            } else {
                                var continueUrl = data.continueUrl;
                                var urlParams = {
                                    ID: data.orderID,
                                    token: data.orderToken
                                };

                                continueUrl +=
                                    (continueUrl.indexOf('?') !== -1
                                        ? '&'
                                        : '?') +
                                    Object.keys(urlParams)
                                        .map(function (key) {
                                            return (
                                                key +
                                                '=' +
                                                encodeURIComponent(
                                                    urlParams[key]
                                                )
                                            );
                                        })
                                        .join('&');
                                window.location.href = continueUrl;
                                defer.resolve(data);
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                            // enable the placeOrder button here
                            $('body').trigger(
                                'checkout:enableButton',
                                $('button.place-order')
                            );
                        }
                    });

                    return defer;
                }
                var p = $('<div>').promise(); // eslint-disable-line
                setTimeout(function () {
                    p.done(); // eslint-disable-line
                }, 500);
                return p; // eslint-disable-line
            },

            /**
             * Initialize the checkout stage.
             *
             * TODO: update this to allow stage to be set from server?
             */
            initialize: function () {
                // set the initial state of checkout
                members.currentStage = checkoutStages.indexOf(
                    $('.data-checkout-stage').data('checkout-stage')
                );
                $(plugin).attr(
                    'data-checkout-stage',
                    checkoutStages[members.currentStage]
                );

                //
                // Handle Payment option selection
                //
                $('input[name$="paymentMethod"]', plugin).on(
                    'change',
                    function () {
                        $('.credit-card-form').toggle(
                            $(this).val() === 'CREDIT_CARD'
                        );
                    }
                );

                //
                // Handle Next State button click
                //
                $(plugin).on('click', '.next-step-button button', function () {
                    members.nextStage();
                });

                //
                // Handle Edit buttons on shipping and payment summary cards
                //
                $('.go-to-shipping', plugin).on(
                    'click',
                    function () {
                        if (!$('#checkout-main').hasClass('multi-ship')) {
                            $('body').trigger('shipping:selectSingleShipping');
                        }

                        members.gotoStage('shipping');
                    }
                );

                //
                // remember stage (e.g. shipping)
                //
                updateUrl(members.currentStage);

                //
                // Listen for foward/back button press and move to correct checkout-stage
                //
                $(window).on('popstate', function (e) {
                    //
                    // Back button when event state less than current state in ordered
                    // checkoutStages array.
                    //
                    if (
                        e.state === null ||
                        checkoutStages.indexOf(e.state) < members.currentStage
                    ) {
                        members.handlePrevStage(false);
                    } else if (
                        checkoutStages.indexOf(e.state) > members.currentStage
                    ) {
                        // Forward button  pressed
                        members.handleNextStage(false);
                    }
                });

                //
                // Set the form data
                //
                plugin.data('formData', formData);

                // Load Bpost shipping manager
                checkToLoadBpost(members.currentStage);
            },

            /**
             * The next checkout state step updates the css for showing correct buttons etc...
             */
            nextStage: function () {
                var promise = members.updateStage();

                promise.done(function () {
                    // Update UI with new stage
                    members.handleNextStage(true);
                });

                promise.fail(function (data) {
                    // show errors
                    if (data) {
                        if (data.errorStage) {
                            members.gotoStage(data.errorStage.stage);

                            if (data.errorStage.step === 'billingAddress') {
                                var $billingAddressSameAsShipping = $(
                                    'input[name$="_shippingAddressUseAsBillingAddress"]'
                                );
                                if (
                                    $billingAddressSameAsShipping.is(':checked')
                                ) {
                                    $billingAddressSameAsShipping.prop(
                                        'checked',
                                        false
                                    );
                                }
                            }
                        }

                        if (data.errorMessage) {
                            $('.error-message').show();
                            $('.error-message-text').text(data.errorMessage);
                        }
                    }
                });
            },

            /**
             * The next checkout state step updates the css for showing correct buttons etc...
             *
             * @param {boolean} bPushState - boolean when true pushes state using the history api.
             */
            handleNextStage: function (bPushState) {
                if (members.currentStage < checkoutStages.length - 1) {
                    // move stage forward
                    members.currentStage++;

                    //
                    // show new stage in url (e.g.payment)
                    //
                    if (bPushState) {
                        updateUrl(members.currentStage);
                    }
                }

                // Set the next stage on the DOM
                $(plugin).attr(
                    'data-checkout-stage',
                    checkoutStages[members.currentStage]
                );

                $(document).trigger('checkout:loadStage', checkoutStages[members.currentStage]);

                checkToLoadBpost(members.currentStage);
            },

            /**
             * Previous State
             */
            handlePrevStage: function () {
                if (members.currentStage > 0) {
                    // move state back
                    members.currentStage--;
                    updateUrl(members.currentStage);
                }

                $(plugin).attr(
                    'data-checkout-stage',
                    checkoutStages[members.currentStage]
                );

                $(document).trigger('checkout:loadStage', checkoutStages[members.currentStage]);
            },

            /**
             * Use window history to go to a checkout stage
             * @param {string} stageName - the checkout state to goto
             */
            gotoStage: function (stageName) {
                members.currentStage = checkoutStages.indexOf(stageName);
                if (members.currentStage >= 0) {
                    updateUrl(members.currentStage);
                    $(plugin).attr(
                        'data-checkout-stage',
                        checkoutStages[members.currentStage]
                    );

                    $(document).trigger('checkout:loadStage', checkoutStages[members.currentStage]);
                }
                checkToLoadBpost(members.currentStage);
            }
        };

        //
        // Initialize the checkout
        //
        members.initialize();

        return this;
    };
}(jQuery));

var exports = {
    initialize: function () {
        parseRealtimeStock($('#realtimeStockInfo').data('stock'));
        $('#checkout-main').checkout();
        $(document).trigger('checkout:loadStage', $('#checkout-main').data('checkout-stage'));
    },
    ConfirmRemoveOutOfStock: function () {
        $('body').on('click', '.checkout-delete-confirmation-btn', function (e) {
            e.preventDefault();
            $('#realtimeStockInfo').attr('data-stock-confirmed', 'true');
            $('.js-stock-ok').removeClass('d-none');
            $('.js-stock-error').addClass('d-none');
            $('body > .modal-backdrop').remove();

            var stage = $('#checkout-main').data('checkout-stage');

            if (stage === 'shipping') {
                $('.next-step-button .js-stock-ok .submit-shipping').click();
            } else if (stage === 'placeOrder') {
                $('.next-step-button .js-stock-ok .place-order').click();
            }
        });
    },
    updateCheckoutView: function () {
        $('body').on('checkout:updateCheckoutView', function (e, data) {
            shippingHelpers.methods.updateMultiShipInformation(data.order);
            summaryHelpers.updateTotals(data.order.totals);
            data.order.shipping.forEach(function (shipping) {
                shippingHelpers.methods.updateShippingInformation(
                    shipping,
                    data.order,
                    data.customer,
                    data.options
                );
            });
            billingHelpers.methods.updateBillingInformation(
                data.order,
                data.customer,
                data.options
            );
            billingHelpers.methods.updatePaymentInformation(
                data.order,
                data.options
            );
        });
    },

    savePaymentInstrument: function () {
        $('input[name=savePaymentMethod]').change(function () {
            if ($(this).prop('checked')) {
                $('input[name=savePaymentMethod]').prop('checked', true).val('true');
            } else {
                $('input[name=savePaymentMethod]').prop('checked', false).val('false');
            }
            submitPaymentForm();
        });
    },

    paymentMethodSelection: function () {
        $('input[name=alias]:not(.alias_false)').change(function () {
            $('input[name=alias]').val($(this).attr('id'));
            $('.js-checkbox').hide();
            submitPaymentForm();
        });

        $('input[name=dwfrm_billing_paymentMethod]').change(function () {
            if (!$(this).closest('.saved-payment-instrument').next().find('input[name=alias]')[0]) {
                $('input[name=alias]').val(false);
                $('.js-checkbox').show();
            } else {
                $('input[name=alias]').val($(this).closest('.saved-payment-instrument').next().find('input[name=alias]')
                    .attr('id'));
                $('.js-checkbox').hide();
            }
            submitPaymentForm();
        });
    },

    newPaymentMethodSelection: function () {
        $('.alias_false').click(function () {
            $('input[name=alias]').val(false);
            $('.js-checkbox').show();
            submitPaymentForm();
        });
    },

    disableButton: function () {
        $('body').on('checkout:disableButton', function (e, button) {
            $(button).prop('disabled', true);
        });
    },

    enableButton: function () {
        $('body').on('checkout:enableButton', function (e, button) {
            $(button).prop('disabled', false);
        });
    },

    optinCheck: function () {
        $('.js-optin input').prop('checked', false);
        $('.js-optin input').on('change', function () {
            $('.js-optin-toggle').toggleClass('d-none');
            $('.js-optin').removeClass('text-red');
        });

        $('span.js-optin-toggle').on('click', function () {
            $('.js-optin').addClass('text-red');
        });
    },
    deletePaymentAlias: function () {
        $('.js-aliasremove-button').on('click', function () {
            removeAliasUrl = $(this).data('url');
            removeAliasId = $(this).data('aliasid');
        });

        $('.js-delete-payment').on('click', function () {
            var url = removeAliasUrl;
            var aliasId = removeAliasId;
            if (!url || !aliasId) {
                return;
            }
            $.spinner().start();
            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: { aliasId: aliasId },
                    success: function (data) {
                        $.spinner().stop();
                        if (data.success === true) {
                            if (removeAliasId) {
                                var elemAlias = $('.' + removeAliasId);
                                // remove alias container from DOM
                                elemAlias.remove();
                            }
                            var aliasValue = $('input[name=alias]').val();
                            if (aliasValue !== 'false') {
                                var elemClosestAlias = $('.js-aliascontainer');
                                // trigger click on the next alias
                                elemClosestAlias.find('input[name=alias]').trigger('click');
                            }
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                }
            );
        });
    },
    editBpostAddress: function () {
        $('body').on('click', '.jquery-bpost-address-edit', function (e) {
            e.preventDefault();
            document.querySelector('.submit-shipping').disabled = true;
            document.getElementById('shm-container').style.height = '620px';
            bpost.loadSHM();
        });
    },
    bpostMessage: function () {
        window.addEventListener('message', (event) => {
            if (event.origin === window.origin && event.data.from === 'bpostPickupPointConfirm') {
                document.querySelector('.submit-shipping').disabled = false;
                document.getElementById('shm-container').style.removeProperty('height');
                document.getElementById('shm-container').innerHTML = event.data.addressAsHtml;
                return;
            }
        }, false);
    },

    submitPhoneForm: function () {
        $('body').on('submit', 'form.js-change-phone-form', function (e) {
            e.preventDefault();
            const $modalSavePhone = $('#modalSavePhone');
            $modalSavePhone.spinner().start();
            var $form = $(this);
            var url = $form.attr('action');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $modalSavePhone.spinner().stop();
                    if (!data.success) {
                        var formValidation = require('../base/components/formValidation');
                        formValidation($form, data);
                    } else {
                        $modalSavePhone.modal('hide');
                        $('#customerPhoneCheckout').text(data.phone);
                        $('.js-phone-change').toggleClass('d-none');
                        $('.js-phone-add').toggleClass('d-none');
                        $modalSavePhone.spinner().stop();
                    }
                },
                error: function (err) {
                    $modalSavePhone.modal('hide');
                    $('body').trigger(
                        'checkout:enableButton',
                        '.next-step-button button'
                    );
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                    $modalSavePhone.spinner().stop();
                }
            });
        });
    },

    validateCustomerNote: function () {
        const customerNote = document.getElementById('customerNote');
        if (customerNote) {
            customerNote.addEventListener('input', function (event) {
                const pattern = /[^\x20-\x7F\xC0-\xFF\n\s€]/g;
                const value = event.target.value;
                const newValue = value.replace(pattern, '');
                if (newValue !== value) {
                    var updatedEvent = event;
                    updatedEvent.target.value = newValue;
                }
            });
        }
    }
};

[billingHelpers, shippingHelpers, addressHelpers].forEach(function (library) {
    Object.keys(library).forEach(function (item) {
        if (typeof library[item] === 'object') {
            exports[item] = $.extend({}, exports[item], library[item]);
        } else {
            exports[item] = library[item];
        }
    });
});

module.exports = exports;
