/* globals SHM, SHMConfig */
'use strict';

/**
 * Check bpost iframe loading status.
 * If bpost services are unavailable, the iframe will not load.
 * If the content is still the same after a timeout, then we show a container
 * explaining the problem to the customer.
*/
function checkBpostStatus() {
    const container = $('#shm-container');
    const contentInitial = container.text();
    setTimeout(function () {
        const contentNow = container.text();
        if (contentNow === contentInitial) {
            container.hide();
            $('#shm-fallback').show();
        }
    }, 5000);
}

/**
 * Loads the bpost shipping manager
 */
function loadSHM() {
    // Check bpost status
    checkBpostStatus();

    if (typeof SHM !== 'undefined' && SHM && SHMConfig) {
        SHM.open({
            integrationType: 'INLINE',
            forceIntegrationType: true,
            inlineContainerId: 'shm-container',
            parameters: {
                accountId: SHMConfig.accountId,
                lang: SHMConfig.lang,
                orderReference: SHMConfig.orderReference,
                orderTotalPrice: SHMConfig.priceTotal.replace(/€| |,/g, ''),
                customerFirstName: SHMConfig.customer.firstName,
                customerLastName: SHMConfig.customer.lastName,
                customerCompany: SHMConfig.customer.company,
                customerStreet: SHMConfig.customer.street,
                customerStreetNumber: SHMConfig.customer.streetNumber,
                customerBox: SHMConfig.customer.postBox,
                customerCity: SHMConfig.customer.city,
                customerPostalCode: SHMConfig.customer.postalCode,
                customerCountry: 'BE',
                customerEmail: SHMConfig.customer.email,
                customerPhoneNumber: SHMConfig.customer.phone,
                deliveryMethodOverrides: [
                    'Regular|INVISIBLE',
                    'Parcels depot|VISIBLE|' + SHMConfig.shippingTotalPrice,
                    'Pugo|VISIBLE|' + SHMConfig.shippingTotalPrice
                ],
                extraSecure: '',
                confirmUrl: SHMConfig.confirmUrl,
                cancelUrl: SHMConfig.cancelUrl,
                checksum: SHMConfig.checksum
            }
        });
    }
}

module.exports = {
    loadSHM
};
