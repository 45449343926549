'use strict';

var GoogleHelper = {};

const appendToUrl = require('../util/appendToUrl');
const defaultRadius = 25;

GoogleHelper.detectStores = function ($form, options, callbackSuccess, callbackError) {
    $.spinner().start();
    if (!navigator.geolocation) {
        $.spinner().stop();
        return;
    }

    navigator.geolocation.getCurrentPosition(function (position) {
        var url = $form.data('action');
        const radius = $form.find('[name="radius"]').val() || defaultRadius;

        var payload = $form.serialize();

        var urlParams = {
            radius: radius,
            lat: position.coords.latitude,
            long: position.coords.longitude,
            apothecary: options.apothecary,
            useLocation: true
        };

        url = appendToUrl(url, urlParams) + '&' + payload;

        $.ajax({
            url: url,
            type: 'get',
            success: function (resultData) {
                $.spinner().stop();
                callbackSuccess(resultData);
            }
        });
    }, () => {
        if (callbackError) {
            callbackError();
        }
        $.spinner().stop();
    });
};

GoogleHelper.searchStores = function ($form, options, callback) {
    $.spinner().start();
    var url = $form.data('action');
    var radius = $form.find('[name="radius"]').val() || defaultRadius;
    var postalCodeUnParsed = $form.find('[name="postalCode"]').val();
    var postalCode = (isNaN(postalCodeUnParsed) || postalCodeUnParsed === '') ? postalCodeUnParsed : 'BE-' + postalCodeUnParsed;
    var apiKey = $form.data('apikey');
    var country = 'Belgie';
    var googleMapsUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
    var googleMapsUrlParams = {
        address: postalCode + ' ' + country,
        components: 'country:BE',
        key: apiKey
    };
    googleMapsUrl = appendToUrl(googleMapsUrl, googleMapsUrlParams);

    $.ajax({
        url: googleMapsUrl,
        type: 'get',
        success: function (data) {
            var location = data.results[0] ? data.results[0].geometry.location : {};

            var payload = $form.serialize();

            var urlParams = {
                radius: radius,
                lat: location.lat,
                long: location.lng,
                apothecary: options.apothecary
            };

            url = appendToUrl(url, urlParams) + '&' + payload;

            $.ajax({
                url: url,
                type: 'get',
                success: function (resultData) {
                    $.spinner().stop();
                    callback(resultData, location);
                }
            });
        }
    });
};

module.exports = GoogleHelper;
