'use strict';

var Notificication = {};

/**
 * Create an alert to display the error message
 * @param {Object} message - Error message to display
 * @param {string} type - Notification type
 */
Notificication.createNotification = (message, type) => {
    var errorHtml = `<div class="alert alert-${type} alert-dismissible valid-cart-error ` +
        'fade show" role="alert">' +
        message + '</div>';

    $('.error-messaging').append(errorHtml);
    $('.error-messaging').show();
    $('.error-messaging').delay(5000).fadeOut(300);
};

module.exports = Notificication;
